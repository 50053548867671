import "../styles/roleManagement.css";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
Modal.setAppElement("#root");


const RoleManagementEdit = () => {
  const accessToken = localStorage.getItem("accessToken");
  const location = useLocation();
  const depId = useParams();
  const navigate = useNavigate();
  const selectedRoleData = location.state?.roleData;
  const [allPermissionsData, setAllPermissionsData] = useState([]);
  const [rolePermissionsData, setRolePermissionsData] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const roleId = selectedRoleData.id;
  const roleName = selectedRoleData.role_name;

  const fetchPermissionsData = async () => {
    try {
      const [allPermissionsResponse, rolePermissionsResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BASE_URL}/permissions`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }),
        axios.get(`${process.env.REACT_APP_BASE_URL}/roles/${roleId}/permissions`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      ]);
  
      const allPermissionsInfo = allPermissionsResponse.data?.data || [];
      const rolePermissionsInfo = rolePermissionsResponse.data?.data || [];

      const updatedPermissionsData = allPermissionsInfo.map(permission => ({
        ...permission,
        selected: rolePermissionsInfo.some(rolePerm => rolePerm.permission_id === permission.permission_id)
      }));

      setAllPermissionsData(updatedPermissionsData);
      setRolePermissionsData(rolePermissionsInfo);
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    fetchPermissionsData();
  }, []);
  

  const submitUpdateRole = async () => {
    try {
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const updatedPermissionIds = allPermissionsData
        .filter(permission => permission.selected)
        .map(permission => permission.permission_id);

      if (updatedPermissionIds.length === 0) {
        alert("No permissions selected.");
        return;
      }

      const data = {
        role_name: roleName,
        permission_ids: updatedPermissionIds,
      };

      const url = `${process.env.REACT_APP_BASE_URL}/roles/${roleId}`;

      await axios.put(url, data, { headers });
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  const handleCheckboxChange = (index) => {
    setAllPermissionsData(prevPermissions =>
      prevPermissions.map((permission, i) =>
        i === index ? { ...permission, selected: !permission.selected } : permission
      )
    );
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <div className="header__titles">
          <button onClick={() => navigate("/rolemanagement")}>
            <span>
              <i className="ri-arrow-left-s-line"></i>
            </span>
            BACK
          </button>
          <h4>Edit Role Management</h4>
        </div>
      </div>
      <div className="role_mgmt_view-container">
        <div className="role_mgmt_view-firstRow">
          <div className="role_mgmt-titles">
            <h6>Company:</h6>
            <p>{selectedRoleData?.company[0].company_name}</p>
          </div>
          <div className="role_mgmt-titles">
            <h6>Department:</h6>
            <p>{selectedRoleData?.department_name}</p>
          </div>
          <div className="role_mgmt-titles">
            <h6>Role:</h6>
            <p>{selectedRoleData?.role_name}</p>
          </div>
        </div>

        <div className="role_management-permissions">
          <h5>Permissions</h5>
          <div className="role_management-container">
            {allPermissionsData.map((permission, index) => (
              <div
                key={permission.permission_id}
                className="role_management-lists"
              >
                <div className="role_management-list">
                  <input
                    type="checkbox"
                    name="permissions[]"
                    checked={permission.selected}
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <p>{permission.name}</p>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>

        <div className="role_form-btn_section" style={{ paddingRight: "30px" }}>
          <button
            type="button"
            className="role_form-btn_cancel"
            onClick={() => navigate("/rolemanagement")}
          >
            Close
          </button>
          <button
            type="button"
            className="role_form-btn_submit"
            onClick={submitUpdateRole}
          >
            Update
          </button>
        </div>
      </div>
      <Modal
        isOpen={showSuccessModal}
        onRequestClose={handleCloseModal}
        contentLabel="Role and Permission Update"
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h5>Updated Successfully</h5>
        <button onClick={handleCloseModal}>OK</button>
      </Modal>
    </div>
  );
};

export default RoleManagementEdit;
