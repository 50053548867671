import "../styles/empManagement.css";
import React, { useCallback, useEffect, useState } from "react";
import EmployeeCard from "../components/Reusable/EmployeeCard";
import { BiSearch } from "react-icons/bi";
import axios from "axios";
import EmployeeManagementTable from "../components/Reusable/EmployeeManagementTable";
import { useNavigate, useLocation } from "react-router-dom";

const EmployeeManagement = () => {
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const [empCard, setEmpCard] = useState(false);
  const [empTable, setEmpTable] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [employeeData, setEmployeeData] = useState("");
  const { state } = useLocation();
  const [selectedCompany, setSelectedCompany] = useState(
    localStorage.getItem("selectedCompany") || ""
  );
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("Sort By Name");
  const [companyList, setCompanyList] = useState([]);
  const navigate = useNavigate();

  const empCardButtonClick = () => {
    setEmpCard(true);
    setEmpTable(false);
  };

  const empTableButtonClick = () => {
    setEmpCard(false);
    setEmpTable(true);
  };

  const getCompanyList = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/companies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => setCompanyList(response.data?.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  const handleAddEmployeeClick = () => {
    navigate("/addemployee");
  };

  const getCompanyId = (companyName) => {
    const company = companyList.find((c) => c.company_name === companyName);
    return company ? company.id : null;
  };

  const handleCompanyChange = (e) => {
    const CompanyId = getCompanyId(e.target.value);
    setSelectedCompany(e.target.value);
    setSelectedCompanyId(CompanyId);
    localStorage.setItem("selectedCompany", e.target.value);
  };

 

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Employee Management</h1>
        <div className="emp__icons">
          <div className="filter__icon">
            <i className="ri-home-4-fill"></i>
            <select
              name="companyList"
              className="filter-select"
              onChange={handleCompanyChange}
              value={selectedCompany}
            >
              <option value="">All Companies</option>
              {companyList.map((company, index) => (
                <option value={company.company_name} key={index}>
                  {company.company_name}
                </option>
              ))}
            </select>
          </div>
          <div className="filter__icon">
            <i className="ri-filter-2-fill"></i>
            <select
              name="filter"
              id="filter"
              className="filter-padding"
              onChange={(e) => setSelectedFilter(e.target.value)}
              value={selectedFilter}
            >
              <option value="">Filter</option>
              <option value="Active">Active</option>
              <option value="In Active">In Active</option>
              <option value="Sort By Name">By Name</option>
              <option value="Sort By Employee ID">By Employee ID</option>
            </select>
          </div>
          <div className="emp__second-header__category04">
            <button onClick={handleAddEmployeeClick}>
              <span>
                <i className="ri-add-line"></i>
              </span>
              Add Employee
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="emp__second-header">
          <div className="emp__second-header__category01">
            <div className="emp__second-section01">
              <div className="emp__button-section">
                <button
                  className={empTable ? "icon__active" : "microsoft__icon"}
                  onClick={empTableButtonClick}
                >
                  <i className="ri-table-line"></i>
                </button>
                <button
                  className={empCard ? "icon__active" : "equalizer__icon"}
                  onClick={empCardButtonClick}
                >
                  <i className="ri-microsoft-fill"></i>
                </button>
              </div>
              <div className="emp__second-header__category02">
                <div className="emp__search-box">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <span>
                    <BiSearch className="font-icon" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="emp__cards">
          {empCard && (
            <EmployeeCard
              selectedCompany={selectedCompany}
              selectedFilter={selectedFilter}
              searchQuery={searchQuery}
            />
          )}
        </div>
        {empTable && (
          <EmployeeManagementTable
            selectedCompanyId={selectedCompanyId}
            selectedCompany={selectedCompany}
            selectedFilter={selectedFilter}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeeManagement;
